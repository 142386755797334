// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-private-projects-js": () => import("./../../../src/pages/private-projects.js" /* webpackChunkName: "component---src-pages-private-projects-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}

